<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">BRANCH INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="branch_code"
                    label="Branch Code"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="address"
                    label="Address"
                    dense
                    outlined
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="contact_no"
                    label="Contact Number"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="email"
                    label="Email Address"
                    dense
                    outlined
                    :rules="rules.email_rule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12" v-if="id==0">
              <v-btn color="primary" class="me-3 mt-4" @click="save_caller" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
            <v-col cols="12" v-else>
              <v-btn color="primary" class="me-3 mt-4" @click="update_info" v-if="!saving"> Update
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-text class="mt-4">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Branch Code</th>
              <th class="text-center text-uppercase">Address</th>
              <th class="text-center text-uppercase">Contact Number</th>
              <th class="text-center text-uppercase">Email Address</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_branch" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.branch_code }}
              </td>
              <td class="text-center">
                {{ item.address}}
              </td>
              <td class="text-center">
                {{ item.contact_no}}
              </td>
              <td class="text-center">
                {{ item.email_address}}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiAccountSearchOutline,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/2.png'),

      saving: false,
      alert: false,
      alert_message: '',

      b_id: 0,
      id: 0,
      contact_no: '',
      email: '',
      address: '',
      branch_code: '',


      list_of_branch: [],
      is_deleting: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
          mdiPencilBoxOutline,
        },
      }
    },
    mounted() {
      this.list_of_branches()
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['save_new_branch', 'get_branches_active']),
      list_of_branches() {
        this.get_branches_active()
          .then(response => {
            this.list_of_branch = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },

      reset() {
        Object.assign(this.$data, initialState())
        this.list_of_branches()
      },
      save_caller() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_code', this.branch_code.toUpperCase())
          data.append(
            'address',
            this.address.toUpperCase(),
          )
          data.append('contact_no', this.contact_no)
          data.append('email_address', this.email)
          this.save_new_branch(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              } else {
                this.reset()
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
            })
            .catch(error => {
              console.log(error)
              this.alert = true
              this.alert_message = error.response.data.message
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    },
  }
</script>
